import cx from 'classnames';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { Icon } from '@sb/design-system';
import type { IconKind } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';
import {
  LINK_TO_HELP_STANDARDBOTS_GUIDE,
  LINK_TO_ROUTINE_LIST_PAGE,
} from '@sbrc/utils';

import { EquipmentManagerModal } from '../equipment-manager';

interface WelcomeViewProps {
  robot: Robot.ConvertedResponse;
}

interface WelcomeViewLinkProps {
  name: string;
  description: string;
  icon: IconKind;
  url: string;
  modalKind?: string;
}

export default function WelcomeView({ robot }: WelcomeViewProps) {
  const { push } = useRouter();
  const { id } = robot;

  const [equipmentModal, setEquipmentModal] = useState<boolean>(false);

  const title = 'Get started';

  const links: WelcomeViewLinkProps[] = [
    {
      name: 'Move the robot',
      description: 'Visualize and control the real robot or a simulated robot.',
      icon: 'tooltip',
      url: `/robots/${id}/move`,
    },
    {
      name: 'Make your first routine',
      description:
        'Complete tasks including moves, speeds, setting/reading IO, communicating with external equipment, and more.',
      icon: 'listBullet',
      url: LINK_TO_ROUTINE_LIST_PAGE,
    },
    {
      name: 'Add equipment',
      description:
        'Manage grippers, 7th axis devices, CNC machines, and more. Equipment is always accessible via the robot menu.',
      icon: 'squareGrid2x2',
      url: '',
      modalKind: 'equipmentManager',
    },
    {
      name: 'Read guides',
      description: 'Read more about the product and how to get stated.',
      icon: 'book',
      url: LINK_TO_HELP_STANDARDBOTS_GUIDE,
    },
  ];

  const welcomeViewLink = ({
    name,
    description,
    icon,
    url,
    modalKind,
  }: WelcomeViewLinkProps) => (
    <div
      className={cx(
        'tw-surface-elevated',
        'tw-shadow-30',
        'tw-px-24',
        'tw-py-20',
        'tw-rounded-18',
        'tw-flex',
        'tw-flex-col',
        'tw-w-[300px]',
        'tw-h-[300px]',
        'tw-overflow-hidden',
        'hover:tw-brightness-110',
      )}
      key={`welcome-${icon}`}
      role="button"
      onClick={() => {
        if (modalKind === 'equipmentManager') {
          setEquipmentModal(true);
        }

        push(url);
      }}
    >
      <h3 className={cx('tw-text-22', 'tw-line-clamp-2', 'tw-mb-2')}>{name}</h3>
      <p className="tw-text-label-tertiary tw-mb-auto">{description}</p>

      <footer>
        <span className="tw-button-36 tw-bg-fill-primary">
          <Icon kind={icon} />
        </span>
      </footer>
    </div>
  );

  return (
    <div className="tw-max-w-940 tw-mt-84 tw-mx-auto">
      <h1 className="tw-heading-60 tw-mb-8">{title}</h1>
      <div className="tw-grid tw-grid-cols-3 tw-gap-20">
        {Object.entries(links).map(([_, props]) => welcomeViewLink(props))}
      </div>

      <EquipmentManagerModal
        isOpen={equipmentModal}
        onClose={() => setEquipmentModal(false)}
        robot={robot}
      />
    </div>
  );
}
